var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"VT6t8unwnNqsZ-RTiT7Bs"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { RewriteFrames } from "@sentry/integrations";
import * as Sentry from "@sentry/nextjs";
import { Integrations } from "@sentry/tracing";

import {
  appENV,
  getAPIBaseUrl,
  sentryDSN,
  sentryRelease,
} from "./src/shared/env";
import { isError } from "./src/shared/lodash";

Sentry.init({
  dsn: sentryDSN,
  release: sentryRelease,
  environment: appENV,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [getAPIBaseUrl().replace("https://", "")],
    }),
    new RewriteFrames({
      root: global.__rootdir__,
    }),
  ],
  tracesSampleRate: 0.2,
  beforeSend(event, hint) {
    // Note: issue with double entries during http exceptions: https://github.com/getsentry/sentry-javascript/issues/2169
    // Note: issue with a second entry not being set correctly (as a non-error): https://github.com/getsentry/sentry-javascript/issues/2292#issuecomment-554932519
    const isNonErrorException =
      (event?.exception?.values &&
        event.exception.values.length > 0 &&
        event.exception.values[0].value?.startsWith(
          "Non-Error exception captured",
        )) ||
      (isError(hint?.originalException) &&
        hint?.originalException?.message?.startsWith(
          "Non-Error exception captured",
        ));

    if (isNonErrorException) {
      if (!event?.extra?.__serialized__) {
        return null;
      }

      const serialized = event.extra?.__serialized__;
      const realErrMsg = serialized?.error
        ? serialized?.error?.message || ""
        : serialized?.message || "";

      // this is a useless error message that masks the actual error.  Lets try to set it properly
      if (event?.exception?.values && event.exception.values[0]) {
        event.exception.values[0].value = realErrMsg;
      }

      event.message = realErrMsg;
    }
    return event;
  },
});
